import { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { Stepper as MUIStepper, Step, StepLabel, Snackbar } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useSteps, useSelectStep } from 'hooks/steps'
import { get, isEmpty } from 'lodash'
import { postEvaluation } from '../../api/services/postEvaluation'
import FormContext from '../../contexts/FormContext'
import { useCreateEvaluation, useSaveEvaluation } from 'hooks/formsDisplay'
import { Alert } from '@material-ui/lab'
import validateDisplayRules, { validateDisplayRulesCustomer } from 'functions/validateDisplayRules'
import { fields } from 'mocks/responses'

const Stepper = ({ steps, customer = {}, send = false, receipt, children }) => {
  const { setActiveStep: setContextStep, setNextStepIsLast } = useContext(FormContext);
  const { selected: stepId } = useSteps()
  const [activeStep, setActiveStep] = useState(0)
  const [skipped, setSkipped] = useState(new Set())
  const selectStep = useSelectStep()
  const classes = useStyles()
  const [state, setSnackbar] = useState({ open: false, severity: 'success' });

  var parentUrl = (window.location != window.parent.location)
    ? document.referrer
    : document.location.href;

  const { open, severity } = state

  const postMessage = () => {
    if (parent && parentUrl)
      parent.postMessage({
        type: "formFinished",
        message: "Formulário finalizado",
        success: true
      }, parentUrl);
  }

  useEffect(() => {
    setContextStep(activeStep)
  }, [activeStep, setContextStep])

  const { mutateAsync: onSubmit, error } = useCreateEvaluation({
    onSuccess: () => {
      window.onbeforeunload = null;
      if (parent && parentUrl) postMessage();
    }
  })

  const { mutateAsync: onSave, errorSave } = useSaveEvaluation({
    onSuccess: () => {
      setSnackbar({ open: true, severity: 'success' });
    }
  })

  useEffect(() => {
    function teste() {
      window.onbeforeunload = function (e) {
        e = e || window.event;
        if (e) {
          e.returnValue = '';
        }
        return '';
      };
    }
    teste()
  }, [])

  useEffect(() => {
    // const min = steps.map(({ order }) => order).reduce((a, b) => Math.min(a, b))
    const step = steps.findIndex((step) => step.id === stepId)

    setActiveStep(step)
  }, [steps, stepId])

  useEffect(() => {
    const step = steps[activeStep];
    if (step && step.id)
      selectStep(step.id)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep])

  const isStepOptional = (step) => step === 0

  const isStepSkipped = (step) => skipped.has(step)

  const windowUp = () => {
    window.scroll(0, 0);
  }

  const decimalField = (data) => {
    const fields = [];
    steps.map(step => {
      step.fields.map(field => fields.push(field))
    })
    const decimalField = fields.find(field => {
      return field.type === 'decimal'
    })
    
    if (!isEmpty(decimalField)) {
      const decimalInput = data[decimalField.id]
      data[decimalField.id] = decimalInput.replace(',', '.');
    }

    return data;
  }

  const imcField = (data) => {
    const fields = [];
    steps.map(step => {
      step.fields.map(field => fields.push(field))
    })
    const IMC = fields.find(field => {
      return field.type === 'imc' || field.type === 'imc_gestacional'
    })

    if ((!isEmpty(IMC) && !isEmpty(data[IMC.id])) || (data['weight'] && data['height'])) {

      data[IMC.id] = null;

      if (Number(data['weight']) > 0 && Number(data['height']) > 0){
        data[IMC.id] = {
          weight: Number(data['weight']),
          height: Number(data['height']),
        }
      }

      delete data.weight;
      delete data.height;
    }

    return data
  }

  const convertCurrencyMaskedValueToFloat = (data) => {
    steps.map(step => {
      step.fields.map(field => {
        if (field.mask === 'currency' && data[field.id]) {
          let cleanedString = data[field.id].replace(/[^\d,]/g, '');
          data[field.id] = parseFloat(cleanedString.replace(',', '.'));
        }
      })
    })

    return data
  }

  const handleSave = (data) => {
    if (send)
      onSave(data)
  }

  const stepsToAdvance = (data) => {
    let addSteps = 1;
    if (isEmpty(steps[activeStep + 1]) || steps[activeStep + 1].display_rules.length == 0) return addSteps
    for (let i = activeStep; i < steps.length; i++) {
      if (!validateDisplayRules(data, steps[activeStep + addSteps]) || !validateDisplayRulesCustomer(customer, steps[activeStep + addSteps])) addSteps += 1
      else break;
    }
    return addSteps;
  }

  const handleNext = (data) => {
    // if (parent && parentUrl) postMessage();
    const addSteps = stepsToAdvance(data);
    let newSkipped = skipped
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values())
      newSkipped.delete(activeStep)
    }

    const nextStep = activeStep + addSteps
    setActiveStep((prevActiveStep) => prevActiveStep + addSteps)
    setSkipped(newSkipped)

    if ((activeStep === (steps.length - 1))) {
      window.onbeforeunload = null
    }

    // if (nextStep === (steps.length)) {
    //   if (parent && parentUrl) postMessage();
    // }

    decimalField(data);

    if ((nextStep === steps.length) && !!send) {
      data = convertCurrencyMaskedValueToFloat(data)
      onSubmit(decimalField(imcField(data)))
      // postEvaluation(data);
    }
    windowUp();
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
    windowUp();
  }

  const handleSkip = (forced) => {
    if (!forced && !isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.")
    }
    windowUp();

    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values())
      newSkipped.add(activeStep)
      return newSkipped
    })
  }

  const receiptOrError = () => {
    if (error) {
      return 'Ocorreu um erro ao enviar o formulário'
    }
    return receipt
  }

  return (
    <div className={classes.wrapper}>
      <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={open} autoHideDuration={6000} onClose={() => setSnackbar({ ...state, open: false })}>
        <Alert variant="filled" onClose={() => setSnackbar({ ...state, open: false })} severity={severity}>
          {severity === 'success' ?
            'Formulário salvo com sucesso!' :
            'Ocorreu um erro ao salvar.'
          }
        </Alert>
      </Snackbar>
      {steps.length > 1 && (
        <MUIStepper activeStep={activeStep}>
          {steps.map(({ id }) => (
            <Step key={id}>
              <StepLabel />
            </Step>
          ))}
        </MUIStepper>
      )}
      {activeStep === steps.length
        ? receiptOrError()
        : children({
          step: steps[activeStep],
          handleNext,
          handleBack,
          handleSkip,
          handleSave,
        })}
    </div>
  )
}

const useStyles = makeStyles(() => ({
  wrapper: {
    maxWidth: '450px',
    minWidth: '300px',
    width: '100%',
    paddingLeft: '12px',
    paddingRight: '12px'
  },
}))

Stepper.propTypes = {
  children: PropTypes.func.isRequired,
  customer: PropTypes.func.isRequired,
  receipt: PropTypes.node.isRequired,
  send: PropTypes.bool.isRequired,
  steps: PropTypes.array.isRequired,
}

export default Stepper
